body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Filter component */
.filter-main-section {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.filter-sec {
  width: 15%;
  border-right: 1px solid #edebef;
  border-top: 1px solid #edebef;

}

.result-sec {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 84%;
  border-top: 1px solid #edebef;
}

@media only screen and (min-width: 320px) and (max-width: 479px){
  .result-sec{
    width: 100%;
  }
  .filter-sec {
    width: 100%;
    border: none;
  }
 }

 @media only screen and (min-width: 768px) and (max-width: 991px){
  .result-sec{
    width: 100%;
  }
  #outlined-basic {
    width: 12ch;
  }
  .filter-sec {
    width: 100%;
    border-right: 1px solid #edebef;
  }
 }
 /* Filter component */


 /* product card discount % */
.discount_percent {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: brown;
}

 /* product card discount % */

.sort-by-sec{
  margin-left: 24px;
}

/* padding between elements of form control */
.PrivateSwitchBase-root-21 {
  padding: 5px !important;
}

/* margin between elements of form control cheeck-box */
.makeStyles-formControl-9 {
  margin: 24px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
/* inner-outer spin button in type=number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiInputBase-input {
  /* height: 0 !important; */
}

